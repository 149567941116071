import React from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from '@fortawesome/free-brands-svg-icons'
import BackgroundImage from 'gatsby-background-image'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab, fas)



const Card = styled.div`
  width:100%;
  height:100%;
  border-radius:5%;
  overflow:hidden;
  position:relative;
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
`;

const Picture = styled(BackgroundImage)`
  width:100%;
  height:100%;
  z-index:0;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
&:hover,&.focus{
    transition:all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    transform:scale(1.3) rotate(10deg);
}
`;
const Des = styled.div`
  z-index:2;
  position: absolute;
  padding: 10px;
  right:15%;
  top: 25%;
  left: 15%;
  bottom: 25%;
  width:65%;
  height:50%;
  display: block;
  background-color: #fffcfc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const Social = styled.div`
  position: relative;
  left:40%;
  border-radius:50%;
  z-index:2;
  top:25%;
  a{
    display:contents;
  }
`;

const IconSpan = styled.span`
  color:#000000;
  padding: 5px;
  &:hover,&.focus{
    color: #58a89e;
  }
`;

const CardText = styled.p`
  padding:0 5px 5px 5px;
  font-size: 1.2rem;
  @media (max-width: 500px) {
    padding:0px;
    font-size:0.9rem;
  }
`;
const TechText = styled.div`
  text-align: center;
  color: black;

`;
const TechSpan = styled.span`
  padding: 5px;
  margin-bottom: 3px;
`;
const TitleText = styled.h3`
  @media (max-width: 500px) {
    line-height: 0.2;
    font-size:1.3rem;

}
`;

const DesCard = ({project}) => {
  return (
    <Des>
    <TitleText>{project.node.frontmatter.title}</TitleText>
    <CardText
      dangerouslySetInnerHTML={{
        __html: project.node.html,
      }}
    />
    <TechText>
      {project.node.frontmatter.tech.map((tech, i) => {
        return (<TechSpan key={i}>{tech}</TechSpan>)
      })}
    </TechText>

    <Social>
    <a href={project.node.frontmatter.github} target='blank' >
    <IconSpan>
    <FontAwesomeIcon icon={["fab", "github"]} size="2x" />
    </IconSpan>
    </a>
    <a href={project.node.frontmatter.external} target='blank' >
    <IconSpan>
    <FontAwesomeIcon icon={["fas", "external-link-alt"]} size="2x" />
    </IconSpan>
    </a>
    </Social>
    </Des>
  )
}

const ProjectCard = ({fluid, project}) => {
  const [isHover, setHover] = React.useState(false)

  const openDes = () => {
    setHover(true);
  }
  const closeDes = () => {
    setHover(false);
  }
  return(
 <Card onMouseEnter={openDes} onMouseLeave={closeDes}>
    {isHover? <DesCard project={project}>
    </DesCard>: null}
   <Picture fluid={fluid} >
   </Picture>

 </Card>
  );
}

export default ProjectCard;
