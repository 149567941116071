import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectGrid from "../components/grid"

const Container = styled.div`
  margin: 0 auto;
  text-align: center
`;

const ProjectPage = ({data}) => {

  return(
  <Layout>
    <SEO title="Project" />
    <section>
    <Container>
      <h2>Web Projects</h2>
    <ProjectGrid data={data}></ProjectGrid>
    <h2>Others</h2>
    </Container>
    </section>
  </Layout>
  )
  }

export default ProjectPage

export const fluidProject = graphql`
fragment fluidProject on File {
  childImageSharp {
    fluid(maxHeight: 600) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "topsongsearch.png" }) {
      ...fluidProject
    }
    imageTwo: file(relativePath: { eq: "myfootprints.png" }) {
      ...fluidProject
    }
    imageThree: file(relativePath: { eq: "amazingshop.png" }) {
      ...fluidProject
    }
    imageFour: file(relativePath: { eq: "3bodypage.png" }) {
      ...fluidProject
    }
    allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "project"}}}
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            github
            external
            tech
          }
        }
      }
    }
  }
`

