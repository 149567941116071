import React from "react"
import ProjectCard from "./card"

const ProjectGrid = ({data}) => {
  const images = [data.imageOne, data.imageTwo, data.imageThree, data.imageFour];
  const projects = data.allMarkdownRemark.edges;
  return(
  <div className = 'gallery'>
    {projects.map((project, i) => {
      return (
      <figure key = {i}>
        {/* <a href ='#'> */}
        <ProjectCard fluid={images[i].childImageSharp.fluid}
        project = {project}></ProjectCard>
        {/* <ImgStyle fluid={data.imageOne.childImageSharp.fluid} title ="project1" alt="project 1"/> */}
        {/* </a> */}
      </figure>

     ) })}
  </div>

  );
}

export default ProjectGrid;

